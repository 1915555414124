<template>
<div>
<v-container>
<div class="conteneur">
  <div class="conteneur-fondu">
      <div v-if="camping_id == 582 || camping_id == 4085 || camping_id == 4535 || camping_id == 6475 || camping_id == 1015" class="logo">
          <img  src="/images/titreMSVacances.png">
      </div>
      <div v-else class="logo">
          <img  src="/images/titreCampeole.png">
      </div>
  </div>

  <div class="conteneur-element-1">
    <div class="element-1">
      <div v-for="(item,index) in questions" :key="item.id">
        <transition :name="slide_sens">
          <v-item-group v-model="form.model[index]" v-if="question_nb==(index+1)" class="container" >
            <!--template format icone simple-->
            <v-row v-if="item.format == 'format-icones' || item.format == 'format-icones-avis' || item.format == 'format-icones-prestations'">
              <v-col cols="12" sm="12">
                <div class="questions">
                <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt=""> Question {{index + 1}} / {{nbTotalQuestion-1}}<br /></div>
                <div class="type-question" :id="'question'+item.id">
                  {{recupQuestionLang(item)}}
                </div>

                </div>
              </v-col>
              <div class="content_answer">
                <div
                v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border"
              >
                  <v-item v-slot="{ active }">
                    <v-card
                      v-if="item.id == 8"
                      sm="3"
                      @click="onCardClickQuestion8(indexSmiley,index, item)"
                    >
                      <v-scroll-y-transition>
                        <div
                          v-if="active"
                          class="active"
                        >
                          <img v-bind:src="url" alt="">
                        </div>
                        <div v-if="!active">
                          <img v-bind:src="url" alt="">
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                    <v-card
                      v-else
                      sm="3"
                      @click="onCardClick(indexSmiley,index, item)"
                    >
                      <v-scroll-y-transition>
                        <div
                          v-if="active"
                          class="active"
                        >
                          <img v-bind:src="url" alt="">
                        </div>
                        <div v-if="!active">
                          <img v-bind:src="url" alt="">
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </div>
              </div>

            </v-row>
            <!--template format icone simple-->

            <!--template format icone avec choix avis-->
            <v-row v-if="item.format == 'format-icones-avis' ">
              <v-col cols="12" sm="12">
                <div class="text-center pointer"  @click="onCardClick(-1,index, item)">
                  <p v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">We have no opinion on the matter</p>
                  <p v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Nous n'avons pas d'avis sur la question</p>
                  <p v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Wir haben dazu keine Meinung</p>
                  <p v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">We hebben er geen mening over</p>
                </div>
              </v-col>
            </v-row>
            <!--template format icone avec choix avis-->

            <!--template format icone avec choix prestation-->
            <v-row v-if="item.format == 'format-icones-prestations' ">
              <v-col cols="12" sm="12">
                <div class="text-center pointer" @click="onCardClick(-1,index, item)">
                  <p v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">We did not take advantage of this service</p>
                  <p v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Nous n'avons pas profité de cette prestation</p>
                  <p v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Diesen Service haben wir nicht in Anspruch genommen</p>
                  <p v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">We hebben geen gebruik gemaakt van deze service</p>
                </div>
              </v-col>
            </v-row>
            <!--template format icone avec choix prestation-->

            <!--template format icone avec choix oui-non-->
            <v-row v-if="item.format == 'format-oui-non'">
              <v-col cols="12" sm="12">
                <div class="questions">
                  <div class="fontfamily-msvacancesregular" @click="before()"><img src="/images/baliseOuverte.png" alt=""> Question {{index + 1}} / {{nbTotalQuestion-1}}<br /></div>
                  <div class="type-question" :id="'question'+item.id">
                    {{recupQuestionLang(item)}}
                  </div>
                </div>
              </v-col>
              <div class="content_answer">
                <div
                  v-for="(label,indexOuinon) in ouinon" :key="indexOuinon"  class="oui-non smiley-border"
                >
                  <v-item v-slot="{ active }">
                    <v-card
                      :color="active ? 'primary' : ''"

                      sm="6"
                      @click="onCardClick(indexOuinon,index, item, 'ouinon')"
                    >
                      <v-scroll-y-transition>
                        <div
                          v-if="active"
                          class="active"
                        >
                          {{label}}
                        </div>
                        <div v-if="!active">
                          {{label}}
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </div>
              </div>
            </v-row>
            <!--template format icone avec choix oui-non-->
            <!--template format icone avec choix txt-libre-->
            <v-row v-if="item.format == 'format-texte-libre'">
              <v-col cols="12" sm="12">
                <div class="questions">
                <div class="fontfamily-msvacancesregular" @click="before()"><img src="/images/baliseOuverte.png" alt=""> Question {{index + 1}} / {{nbTotalQuestion-1}}<br /></div>
                <div class="type-question type-question-fr" :id="'question'+item.id">
                  {{recupQuestionLang(item)}}
                </div>
                </div>
              </v-col>
              
              <v-col cols="12" sm="12" v-if="item.id == 9">
                  <v-textarea
                      clearable
                      counter="300"
                      background-color="#fff"
                      color = "black"
                      clear-icon="mdi-close-circle"
                      :rules="nameRules"
                      v-model="texteLibre9"
                    ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" v-if="item.id == 13">
                  <v-textarea
                      clearable
                      counter="300"
                      background-color="#fff"
                      color = "black"
                      clear-icon="mdi-close-circle"
                      :rules="nameRules"
                      v-model="texteLibre13"
                    ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" v-if="item.id == 17">
                  <v-textarea
                      clearable
                      counter="300"
                      background-color="#fff"
                      color = "black"
                      clear-icon="mdi-close-circle"
                      :rules="nameRules"
                      v-model="texteLibre17"
                    ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <template v-if="item.id == 9">
                <v-btn v-if="texteLibre9 != null && texteLibre9.length > 1" elevation="2" @click="onCardClick(texteLibre9,index, item)">Suivant</v-btn>
                <v-btn v-else elevation="2" disabled>Suivant</v-btn>
                </template>
                <template v-if="item.id == 13">
                <v-btn v-if="texteLibre13 != null && texteLibre13.length > 1" elevation="2" @click="onCardClick(texteLibre13,index, item)">Suivant</v-btn>
                <v-btn v-else elevation="2" disabled>Suivant</v-btn>
                </template>
                <template v-if="item.id == 17">
                <v-btn elevation="2" @click="onCardClick(texteLibre17,index, item)">Suivant</v-btn>
                </template>
              </v-col>
            </v-row>
            <!--template format icone avec choix txt-libre-->

            <!--template format choix multiple-->
            <v-row v-if="item.format == 'format-choix-multiples'" justify="space-around">
              <v-col cols="12" sm="12">
                <div class="questions">
                <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt=""> Question {{index + 1}} / {{nbTotalQuestion-1}}<br /></div>
                <div class="type-question" :id="'question'+item.id">
                  {{recupQuestionLang(item)}}
                </div>

                </div>
                
              </v-col>
              
              <v-col v-if="item.id == 1" lg="6" sm="10" >
                <div class="content_answer">
                  <v-row class="choix-multiples" >
                      <v-col cols="12"><v-checkbox v-model="checkbox1"  label="J'ai déjà séjourné dans l'un de vos établissements" value="1"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox1"  label="J'ai déjà travaillé dans l'un de vos établissements" value="2"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox1"  label="Grâce à mon entourage" value="3"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox1"  label="En naviguant sur internet" value="4"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox1"  label="Autre" value="5"></v-checkbox></v-col>
                      <v-col cols="12"><v-textarea v-if="checkbox1.includes('5')"
                                        clearable
                                        counter="255"
                                        background-color="#fff"
                                        color = "black"
                                        clear-icon="mdi-close-circle"
                                        value=""
                                        placeholder="Précisez..."
                                        :rules="nameRules"
                                        v-model="checkbox1Autre"
                                      ></v-textarea>
                      </v-col>
                  </v-row>
                  <v-btn v-if="(!checkbox1.includes('5') && checkbox1.length > 0) || (checkbox1.includes('5') && checkbox1Autre.length > 1)"  @click="onCardClickChoixMultiples(checkbox1,checkbox1Autre,index, item,'choixMultiples')">Suivant</v-btn>
                  <v-btn v-else disabled>Suivant</v-btn>
                </div>
              </v-col>
              <v-col v-if="item.id == 2" lg="6" sm="10" >
                <div class="content_answer">
                  <v-row class="choix-multiples" >
                      <v-col cols="12"><v-checkbox v-model="checkbox2"  label="La localisation" value="1"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox2"  label="Le standing de l'établissement " value="2"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox2"  label="La description du poste" value="3"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox2"  label="Autre" value="4"></v-checkbox></v-col>
                      <v-col cols="12"><v-textarea v-if="checkbox2.includes('4')"
                                        clearable
                                        counter="255"
                                        background-color="#fff"
                                        color = "black"
                                        clear-icon="mdi-close-circle"
                                        value=""
                                        placeholder="Précisez..."
                                        :rules="nameRules"
                                        v-model="checkbox2Autre"
                                      ></v-textarea>
                      </v-col>
                  </v-row>
                  <v-btn v-if="(!checkbox2.includes('4') && checkbox2.length > 0) || (checkbox2.includes('4') && checkbox2Autre.length > 1)"  @click="onCardClickChoixMultiples(checkbox2,checkbox2Autre,index, item,'choixMultiples')">Suivant</v-btn>
                  <v-btn v-else disabled>Suivant</v-btn>
                </div>
              </v-col>
              <v-col v-if="item.id == 3" lg="6" sm="10" >
                <div class="content_answer">
                  <v-row class="choix-multiples" >
                      <v-col cols="12"><v-checkbox v-model="checkbox3"  label="J'ai postulé sur le site MS Vacances " value="1"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox3"  label="J'ai postulé avec une candidature spontannée" value="2"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox3"  label="J'ai postulé sur les réseaux sociaux" value="3"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox3"  label="J'ai postulé sur indeed" value="4"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox3"  label="j'ai des contacts au sein de l'établissement ou du groupe" value="5"></v-checkbox></v-col>
                      <v-col cols="12"><v-checkbox v-model="checkbox3"  label="Autre" value="6"></v-checkbox></v-col>
                      <v-col cols="12"><v-textarea v-if="checkbox3.includes('6')"
                                        clearable
                                        counter="255"
                                        background-color="#fff"
                                        color = "black"
                                        clear-icon="mdi-close-circle"
                                        value=""
                                        placeholder="Précisez..."
                                        :rules="nameRules"
                                        v-model="checkbox3Autre"
                                      ></v-textarea>
                      </v-col>
                  </v-row>
                  <v-btn v-if="(!checkbox3.includes('6') && checkbox3.length > 0) || (checkbox3.includes('6') && checkbox3Autre.length > 1)"  @click="onCardClickChoixMultiples(checkbox3,checkbox3Autre,index, item,'choixMultiples')">Suivant</v-btn>
                  <v-btn v-else disabled>Suivant</v-btn>
                </div>
              </v-col>
            </v-row>
            <!--template format choix multiples-->

            <!--template format icone texte-->
            <v-row v-if="item.format == 'format-icones-texte'" justify="space-around">
              <v-col cols="12" sm="12">
                <div class="questions">
                <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt=""> Question {{index + 1}} / {{nbTotalQuestion-1}}<br /></div>
                <div class="type-question" :id="'question'+item.id">
                  {{recupQuestionLang(item)}}
                </div>

                </div>
              </v-col>

              <div v-if="item.id == 4" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[3] == 0 || form.response[3] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte4Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[3] == 0 || form.response[3] == 1) && texte4Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[3],texte4Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 5" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[4] == 0 || form.response[4] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte5Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[4] == 0 || form.response[4] == 1) && texte5Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[4],texte5Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 6" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[5] == 0 || form.response[5] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte6Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[5] == 0 || form.response[5] == 1) && texte6Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[5],texte6Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 7" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[6] == 0 || form.response[6] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte7Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[6] == 0 || form.response[6] == 1) && texte7Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[6],texte7Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 8" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[7] == 0 || form.response[7] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte8Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[7] == 0 || form.response[7] == 1) && texte8Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[7],texte8Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 10" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones10(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[9] == 0 || form.response[9] == 1 || form.response[9] == 2 || form.response[9] == 3"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Avez-vous des suggestions ?"
                                          :rules="nameRules"
                                          v-model="texte10Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[9] == 0 || form.response[9] == 1 || form.response[9] == 2 || form.response[9] == 3) && texte10Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[9],texte10Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 11" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[10] == 0 || form.response[10] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte11Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[10] == 0 || form.response[10] == 1) && texte11Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[10],texte11Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 12" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[11] == 0 || form.response[11] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte12Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[11] == 0 || form.response[11] == 1) && texte12Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[11],texte12Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 13" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[12] == 0 || form.response[12] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte13Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[12] == 0 || form.response[12] == 1) && texte13Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[12],texte13Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
              <div v-if="item.id == 14" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[13] == 0 || form.response[13] == 1"
                                          clearable
                                          counter="255"
                                          background-color="#fff"
                                          color = "black"
                                          clear-icon="mdi-close-circle"
                                          value=""
                                          placeholder="Pourquoi avez-vous eu ce ressenti ?"
                                          :rules="nameRules"
                                          v-model="texte14Autre"
                                        ></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[13] == 0 || form.response[13] == 1) && texte14Autre.length > 1)"  @click="onCardClickChoixMultiples(form.response[13],texte14Autre,index, item,'iconestexte')">Suivant</v-btn>
                <v-btn v-else disabled>Suivant</v-btn>
              </div>
            </v-row>
            <!--template format icone texte-->

            <!--template format choix multiple-->
            <v-row v-if="item.format == 'format-anonyme'" justify="space-around">
              <v-col cols="12" sm="12">
                <div class="questions">
                <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt=""> Question {{index + 1}} / {{nbTotalQuestion-1}}<br /></div>
                <div class="type-question" :id="'question'+item.id">
                  {{recupQuestionLang(item)}}
                </div>

                </div>
                
              </v-col>
              
              <v-col lg="6" sm="10" >
                <div class="content_answer">
                  <v-row class="choix-multiples" >
                      <v-col cols="12">
                        <v-radio-group v-model="radio1">
                          <v-radio  label="J’accepte d'être recontacté" key="1"  value="1"></v-radio>
                          <v-radio  label="Je souhaite rester anonyme" key="2"  value="2"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12"><v-text-field v-if="radio1 == 1"
                                        clearable
                                        counter="100"
                                        background-color="#fff"
                                        color = "black"
                                        clear-icon="mdi-close-circle"
                                        value=""
                                        placeholder="Merci de nous communiquer votre Nom"
                                        :rules="nameRules"
                                        v-model="radio1Autre"
                                      ></v-text-field>
                      </v-col>
                  </v-row>
                  <v-btn v-if="(radio1 == 2 && radio1.length > 0) || (radio1 == 1 && radio1Autre.length > 1)"  @click="onCardClickChoixMultiples(radio1,radio1Autre,index, item,'choixRadio')">Suivant</v-btn>
                  <v-btn v-else disabled>Suivant</v-btn>
                </div>
              </v-col>
            </v-row>
            <!--template format choix multiples-->
          </v-item-group>
        </transition>
      </div>
      <div v-if="valueOuinon && nbTotalQuestion == question_nb">
        <v-row v-if="valueOuinon == 'Oui' ">
          <v-col cols="12" sm="12">
            <div class="fontfamily-msvacancesregular">Merci !</div>
            <div class="type-question">
              <strong>Visiblement votre saison<br>fut une réussite et nous en sommes<br>très heureux!<br></strong>
            </div>
            <div class="separation"></div>
            <div class="type-question">
              <strong>Nous serions ravis que vous<br> partagiez votre Expérience professionnelle sur Indeed</strong>
            </div>

            <div>
              <div class="lien-tripadvisor">
                <a target="_blank" href=" https://fr.indeed.com/survey/mc?cid=8ae628f9df4557a6"><img src="/images/indeed_logo.png" alt="" /></a>
              </div>
            </div>
            
          </v-col>
        </v-row>
        <v-row v-if="valueOuinon == 'Non' ">
          <v-col cols="12" sm="12">
            <div class="fontfamily-msvacancesregular">Merci !</div>
            
            <div class="type-question">
              <strong>Votre retour nous permet de <br>
              travailler nos éventuels points<br>
              d'amélioration et ainsi parfaire<br>
              votre Expérience professionnelle.</strong>
            </div>
            
            
          </v-col>
        </v-row>
      </div>
    <div>
  </div>
  </div>
  </div>
</div>
</v-container>
</div>
</template>

<script>

import axios from '@/axios.js'

export default {
  data() {
    return {
      question_format: null,
      nbTotalQuestion : null,
      valueOuinon : null,
      texteLibre9 : null,
      texteLibre13 : null,
      texteLibre17 : null,
      campagne_id: null,
      form_id : null,
      client_id : null,
      envoi_id : null,
      camping_id : null,
      segment_id : null,
      date_envoi : null,
      date_reponse : null,
      smiley:[
        '/images/smiley1.png',
        '/images/smiley2.png',
        '/images/smiley3.png',
        '/images/smiley4.png'
      ],
      ouinon : [
        'Oui',
        'Non',
      ],
      questions : [],
      form: {
        model: [],
        response : [],
      },
      question_nb : 1,
      active : false,
      slide_sens : 'gauche',
      nameRules: [
        v => (v || '' ).length <= 300 || 'Ne peut pas excéder 300 caractères'
      ],
      ruleChoixMultiples: [
        v => (v || '' ).length <= 300 || 'Ne peut pas excéder 300 caractères'
      ],
      checkbox1: [],
      checkbox1Autre: '',
      checkbox2: [],
      checkbox2Autre: '',
      checkbox3: [],
      checkbox3Autre: '',
      texte4Autre:'',
      texte5Autre:'',
      texte6Autre:'',
      texte7Autre:'',
      texte8Autre:'',
      texte10Autre:'',
      texte11Autre:'',
      texte12Autre:'',
      texte13Autre:'',
      texte14Autre:'',
      radio1: '',
      radio1Autre: '',

    }
  },
  created(){
    this.form_id = this.$route.params.form_id
    this.client_id = this.$route.params.client_id
    this.envoi_id = this.$route.params.envoi_id
    //getDataForm
    this.getDataForm()
    //getDataEnvoi
    this.getDataEnvoi()

    this.getLastQuestion()
  },
  methods:{
    getDataEnvoi(){
      return new Promise (() => {
        axios.get('https://api-questionnairesalarie.ms-vacances.com/api/envois/'+this.envoi_id, {
          header: {

          }
        }).then((response) =>{
          console.log()
          this.camping_id = response.data.envoi.camping_id
          this.segment_id = response.data.envoi.segment_id
          this.date_envoi = response.data.envoi.date_envoi
          this.campagne_id = response.data.envoi.campagne_id
        })
      })
    },
    getDataForm() {
      return new Promise (() => {
        axios.get('https://api-questionnairesalarie.ms-vacances.com/api/formulaires/'+this.form_id, {
          header: {

          }
        }).then((response) =>{
          this.questions = response.data.formulaire.questions
          this.nbTotalQuestion = (response.data.total_question + 1) //On ajoute page merci
          //console.log(response.data.formulaire)
        })
      })
    },
    getLastQuestion() {
      return new Promise (() => {
        axios.get('https://api-questionnairesalarie.ms-vacances.com/api/formulaires/'+this.envoi_id+'/'+this.form_id+'/'+this.client_id+'/getlastidquestion', {
          header: {

          }
        }).then((response) =>{
          // Valentin client test infini
          if(this.client_id != '1' && response.data.lastquestion > 1){
            this.question_nb = (response.data.lastquestion) + 1
          }
        })
      })
    },
    onCardClick(n,q,item,type = null){
      let value = 0
      if(typeof n === "string"){
        value = n
        if(n.length > 300) {
          value = n.slice(0, 300)
        }else{
          value = n
        }
        this.$set(this.form.model, q, n)
        this.$set(this.form.response, q, n)
      } else if (n<0) { //cas aucun avis
        this.$set(this.form.model, q, n)
        this.$set(this.form.response, q, n)
      } else {
        value = parseInt((n)) + 1
        if(type=="ouinon") {
          if(value == 2) {
            this.valueOuinon = 'Non'
          } else {
            this.valueOuinon = 'Oui'
          }
        }
        value = parseInt((n)) + 1
        this.$set(this.form.model, (q), parseInt((n)))
        this.$set(this.form.response, (q), parseInt((n)))
      }
      this.saveResult(item,value)
      this.question_nb = (q+1) + 1
      this.slide_sens = 'gauche'
    },
    onCardClickQuestion8(n,q,item){
      let value = 0
      
      value = parseInt((n)) + 1
      this.$set(this.form.model, (q), parseInt((n)))
      this.$set(this.form.response, (q), parseInt((n)))
      
      this.saveResult(item,value)

      console.log(this.form.response[5])
      console.log(this.form.response[6])
      console.log(this.form.response[7])
      if(this.form.response[5] > 1 && this.form.response[6] > 1 && this.form.response[7] > 1){
        this.question_nb = (q+1) + 2
        this.slide_sens = 'gauche'
      }else{
        this.question_nb = (q+1) + 1
        this.slide_sens = 'gauche'
      }
      
    },

    onCardClickIcones(n,q,item){
      let value = 0

      value = parseInt((n)) + 1
      this.$set(this.form.model, (q), parseInt((n)))
      this.$set(this.form.response, (q), parseInt((n)))
      

      if(value <= 2){
        
        //this.saveResultChoixMultiples(item,value,valueTexte)
      }else{
        this.saveResult(item,value)
        this.question_nb = (q+1) + 1
        this.slide_sens = 'gauche'
      }
    },
    onCardClickIcones10(n,q,item){
      let value = 0

      value = parseInt((n)) + 1
      this.$set(this.form.model, (q), parseInt((n)))
      this.$set(this.form.response, (q), parseInt((n)))
      

      if(value <= 4){
        
        //this.saveResultChoixMultiples(item,value,valueTexte)
      }else{
        this.saveResult(item,value)
        this.question_nb = (q+1) + 1
        this.slide_sens = 'gauche'
      }
    },
    onCardClickChoixMultiples(n,texte,q,item,type = null){
      let value = 0
      let valueTexte = ''
      if(type == "choixMultiples") {
        this.$set(this.form.model, (q), n)
        this.$set(this.form.response, (q), n)
        value = n
        valueTexte = texte
      }
      if(type == "iconestexte") {
        this.$set(this.form.model, (q), n)
        this.$set(this.form.response, (q), n)
        value = n+1
        valueTexte = texte
      }
      if(type == "choixRadio") {
        this.$set(this.form.model, (q), n)
        this.$set(this.form.response, (q), n)
        value = n
        valueTexte = texte
      }


      this.saveResultChoixMultiples(item,value,valueTexte)
      this.question_nb = (q+1) + 1
      this.slide_sens = 'gauche'
    },
    saveResult(item,value) {
      const timeElapsed = Date.now()
      const today = new Date(timeElapsed)
      this.date_reponse = today.toISOString()
      if(item.format == 'format-icones' || item.format == 'format-icones-texte' || item.format == 'format-icones-prestations') {
        this.question_format = 'icones'
      }else if(item.format =='format-texte-libre') {
        this.question_format = 'texte-libre'
      }else {
        this.question_format = 'ouinon'
      }
      return new Promise (() => {
          axios.post('https://api-questionnairesalarie.ms-vacances.com/api/resultats/add-result', {
            header: {

            },
            data : {
              form_id: this.form_id,
              envoi_id: this.envoi_id,
              client_id: this.client_id,
              camping_id : this.camping_id,
              segment_id : this.segment_id,
              date_envoi : this.date_envoi,
              question_id : item.id,
              reponse : value,
              reponseTexte : null,
              date_reponse : this.date_reponse,
              question_format: this.question_format,
              campagne_id: this.campagne_id,
            }
          }).then((response) =>{
            return response
          })
      })
    },
    saveResultChoixMultiples(item,value,valueTexte) {
      const timeElapsed = Date.now()
      const today = new Date(timeElapsed)
      this.date_reponse = today.toISOString()
      if(item.format == 'format-choix-multiples') {
        this.question_format = 'choix-multiples'
      }
      if(item.format == 'format-icones-texte') {
        this.question_format = 'icones-texte'
      }
      if(item.format == 'format-anonyme') {
        this.question_format = 'anonyme'
      }
      return new Promise (() => {
          axios.post('https://api-questionnairesalarie.ms-vacances.com/api/resultats/add-result', {
            header: {
            },
            data : {
              form_id: this.form_id,
              envoi_id: this.envoi_id,
              client_id: this.client_id,
              camping_id : this.camping_id,
              segment_id : this.segment_id,
              date_envoi : this.date_envoi,
              question_id : item.id,
              reponse : value.toString(),
              reponseTexte : valueTexte,
              date_reponse : this.date_reponse,
              question_format: this.question_format,
              campagne_id: this.campagne_id,
            }
          }).then((response) =>{
            return response
          })
      })
    },
    recupQuestionLang(item) {
      var toto
      
      toto = item.question
          
      //console.log(this.segment_id)
      return toto
    },
    before(){
      if(this.question_nb <= 1) {
        return false
      }
      this.question_nb = this.question_nb - 1
      this.slide_sens = 'droite'
    }
  }
}
</script>

<style>
/* Chargement police css personnaliséé*/

@font-face {font-family:'msvacancesregular';
    src:url('/fonts/msvacancesregular-webfont.eot');
    src:url('/fonts/msvacancesregular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/msvacancesregular-webfont.woff2') format('woff2'),
    url('/fonts/msvacancesregular-webfont.woff') format('woff'),
    url('/fonts/msvacancesregular-webfont.ttf') format('truetype'),
    url('/fonts/msvacancesregular-webfont.svg#msvacancesregular') format('svg');
    font-weight:normal;
    font-style:normal;
}

.v-main__wrap > div > .container{
  padding: 0 !important;
}

.logo{
    max-width: 50%;
    margin: 0 auto;
}
.logo img{
    max-width: 100%;
}
body{
    margin: 0;
    box-sizing: border-box;
    color: #252525;

}
body .conteneur{
    max-width: 767px;
    height: 100vh;
    /*min-height:1450px;*/
    width: 100%;
    margin: 0 auto;
    background: url("/images/fond_mobile_def.jpg");
    background-size: cover;
    /* background-position: top bottom; */
    background-repeat: no-repeat no-repeat;
    text-align: center;
}
@media(min-width:768px){
  body .conteneur{
      /*max-width: 1500px;*/
      max-width: 100%;
      height: 100vh;
      /*min-height:1450px;*/
      background: url("/images/fond_bureau_def.jpg");
      background-size: cover;

  }
}
.conteneur img{
    /* margin-top: 131px; */
    margin-top: 80px;
    max-width:150px;
}

/* CSS de la partie 1 */


.fontfamily-msvacancesregular{
    font-family: 'msvacancesregular';
    font-size: 42px;
    margin: 0;
    margin-top:40px;
    margin-bottom:38px;
}
.questions .fontfamily-msvacancesregular img{
  max-height:25px;
  -webkit-transition: all 0.2s ease; /* Safari et Chrome */
  -moz-transition: all 0.2s ease; /* Firefox */
  -ms-transition: all 0.2s ease; /* Internet Explorer 9 */
  -o-transition: all 0.2s ease; /* Opera */
  transition: all 0.2s ease;
  cursor:pointer;
}
.questions .fontfamily-msvacancesregular img:hover{
   -webkit-transform:scale(1.15); /* Safari et Chrome */
  -moz-transform:scale(1.15); /* Firefox */
  -ms-transform:scale(1.15); /* Internet Explorer 9 */
  -o-transform:scale(1.15); /* Opera */
  transform:scale(1.15);
}
.oui-non:nth-child(1) div{
  width:70px;
  height:70px;
  background:#7da186;
  color:#fff;
  border-radius:50%;
}
.oui-non:nth-child(2) div{
  width:70px;
  height:70px;
  background:#bd673e;
  color:#fff;
  border-radius:50%;
}
.oui-non div div{
  font-size: 25px !important;
  line-height:47px;
  font-family: Helvetica;
  padding:10px 0;
  text-align:center;
}

.type-question{
  font-size: 21px;
  font-family: Helvetica;
}
.type-question strong{
  font-weight:500;
}

.element-1:after{
    clear:both;
    content:"";
    display:block;
}

.element-1 p{
    font-family: Helvetica;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
}
.content_answer{
  margin:0 auto;
}
.smiley,
.oui-non{
    max-width: 70px;
    width: auto;
    float: left;
    box-sizing: border-box;
    margin: 0 10px;
    margin-top: 30px;
}
.oui-non{
  float: right;
}
.element-1 img{
    margin-top: 0px;
}
.smiley div{
  font-size:1px;
}
.smiley img{
    max-width: 100%;
}
.smiley-border > div > div{
    border: 3px solid transparent;
    border-radius: 75px !important;
    cursor: pointer;
}
.smiley-border:hover > div > div img,
.smiley-border .active img{
    /* border: 3px solid black; */

    border-radius: 75px;
    /*box-shadow: 0 0 0 2px black;*/
}

.smiley-border > div > div img,
.smiley-border img,
.oui-non.smiley-border  div  div{
-webkit-transition: all 0.3s ease; /* Safari et Chrome */
-moz-transition: all 0.3s ease; /* Firefox */
-ms-transition: all 0.3s ease; /* Internet Explorer 9 */
-o-transition: all 0.3s ease; /* Opera */
transition: all 0.3s ease;
border:3px solid transparent;
border-radius:50px;
}
.smiley-border:hover > div > div img,
.smiley-border .active img,
.oui-non.smiley-border:hover  div  div{
-webkit-transform:scale(1.25); /* Safari et Chrome */
-moz-transform:scale(1.25); /* Firefox */
-ms-transform:scale(1.25); /* Internet Explorer 9 */
-o-transform:scale(1.25); /* Opera */
transform:scale(1.25);
border:3px solid #000;
}


.v-textarea{
  padding:0 15px;

}

.v-input__slot{
  border:2px solid #252525 !important;
  padding:15px;
}
.v-input__slot textarea{
  font-size:18px;
  font-family:"Helvetica";
  color:#252525;
}

.v-input__slot .v-input__append-inner{
  display:none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  border:none !important;
}
.v-text-field > .v-input__control > .v-input__slot:after{
  display:none !important;
}

.pointer {
  cursor: pointer;
  -webkit-transition: all 0.1s ease; /* Safari et Chrome */
  -moz-transition: all 0.1s ease; /* Firefox */
  -ms-transition: all 0.1s ease; /* Internet Explorer 9 */
  -o-transition: all 0.1s ease; /* Opera */
  transition: all 0.1s ease;
}
.pointer:hover{
   -webkit-transform:scale(1.05); /* Safari et Chrome */
  -moz-transform:scale(1.05); /* Firefox */
  -ms-transform:scale(1.05); /* Internet Explorer 9 */
  -o-transform:scale(1.05); /* Opera */
  transform:scale(1.05);
}

/*Override Vuetify*/

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.theme--light.v-card, .theme--light.v-sheet {
     background: none !important;
}

.separation{
  width:33%;
  max-width:332px;
  height:2px;
  background:#a0a0a0;
  margin:30px auto;
}
.lien-google img,
.lien-tripadvisor img{
  max-width:250px;
  max-height:70px;
  margin-top:15px;
}

.v-button,
.theme--light.v-btn.v-btn--has-bg{
  background-color:#7da186 !important;
  color:#fff;
  -webkit-transition: all 0.2s ease; /* Safari et Chrome */
  -moz-transition: all 0.2s ease; /* Firefox */
  -ms-transition: all 0.2s ease; /* Internet Explorer 9 */
  -o-transition: all 0.2s ease; /* Opera */
  transition: all 0.2s ease;
  cursor:pointer;
}

.que.v-button:hover,
.theme--light.v-btn.v-btn--has-bg:hover{
   -webkit-transform:scale(1.15); /* Safari et Chrome */
  -moz-transform:scale(1.15); /* Firefox */
  -ms-transform:scale(1.15); /* Internet Explorer 9 */
  -o-transform:scale(1.15); /* Opera */
  transform:scale(1.15);
}


@media(max-width:767px){
  .conteneur .logo img{
      /* margin-top: 131px; */
      margin-top: 50px;
      max-width:100px;
  }
  /* .questions{
    height:100px;
  } */
  .fontfamily-msvacancesregular{
    font-size:30px;
  }
  .questions .fontfamily-msvacancesregular img{
    max-height:20px;
  }
  .type-question{
    font-size:18px;
    padding:0 10px;
  }
  .smiley{
    max-width:74px;
    margin:0 2px;
    margin-top:30px;
  }
  .oui-non{
    max-width:76px;
    margin:0 10px;
    margin-top:30px;
  }

  .element-1 p{
    font-size:14px;
  }

  .oui-non:nth-child(1) div,
  .oui-non:nth-child(2) div{
    width:70px;
    height:70px;
    font-weight:bold;
  }
  .oui-non div div{
    font-size:22px !important;
    padding:0 0;
    line-height:65px;
  }

  .v-input__slot textarea{
    font-size:17px;
  }
  /* body .conteneur{
     min-height:800px;
  } */

  .separation{
    width:80%;
  }
  .v-button,
  .theme--light.v-btn.v-btn--has-bg{
    margin-top:-20px
  }
}
@media(min-width:440px) and (max-width:767px){
  body .conteneur{
    background-size:cover;
  }
}


.v-input--checkbox .v-messages{
  display:none;
}
.v-input--checkbox .v-input__slot{
  padding-top:5px;
  padding-bottom:5px;
  margin:0;
}


/* .v-item-group{
  height:100vh;
} */

.gauche-enter-active {
  transition: all 0.5s ease;
}
.gauche-leave-active {
  transition: all 0s ;
}
.gauche-enter
/* .gauche-leave-active below version 2.1.8 */ {
  transform: translateX(250px);
  opacity: 0;
}

.gauche-leave-to{
  display:none;
}


.droite-enter-active {
  transition: all 0.5s ease;
}
.droite-leave-active {
  transition: all 0s ;
}
.droite-enter
/* .droite-leave-active below version 2.1.8 */ {
  transform: translateX(-250px);
  opacity: 0;
}

.droite-leave-to{
  display:none;
}

.v-input.v-textarea{
  max-width:800px;
  margin:0 auto;
}

@media(max-width:767px){
  .v-item-group{
    height:auto;
  }
  .smiley-border > div > div img,
  .smiley-border img{
    border:2px solid transparent;
  }
  .smiley-border:hover > div > div img,
  .smiley-border .active img,
  .oui-non.smiley-border:hover  div  div{
    -webkit-transform:scale(1.15); /* Safari et Chrome */
    -moz-transform:scale(1.15); /* Firefox */
    -ms-transform:scale(1.15); /* Internet Explorer 9 */
    -o-transform:scale(1.15); /* Opera */
    transform:scale(1.15);
    border:2px solid #000;
  }

  .type-question-fr#question15{
    max-width:250px;
    margin:0 auto;
    padding:0 !important;
  }
  .type-question-fr#question13{
    max-width:225px;
    margin:0 auto;
    padding:0 !important;
  }
}

@media(min-width:768px){
  .container{
    max-width:100% !important;
  }
  .logo img{
    margin-top:40px;
    margin-bottom:-15px;
  }
}

.v-input--checkbox .v-input__slot{
  border:1px solid #252525 !important;
}
.v-input--checkbox .v-input__slot label,
.v-input--radio-group__input label{
  color:#252525 !important;
}

</style>
